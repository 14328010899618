var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        justify: "center",
        value: _vm.value,
        persistent: "",
        "max-width": "500",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-4", attrs: { color: "fill" } },
        [
          _c(
            "v-icon",
            { staticClass: "mr-3", attrs: { color: "primary", "x-large": "" } },
            [_vm._v("mdi-alert-circle-outline")]
          ),
          _c(
            "v-card-text",
            { staticClass: "py-6 font-weight-bold black--text text-center" },
            [
              _vm.hosting && _vm.pangea && _vm.connect
                ? _c("span", [
                    _vm._v(
                      " You are about to create new Hosting, Pangea, and Connect files for the current Ad. These files will be posted to the production FTP site for processing. "
                    ),
                  ])
                : _vm.hosting && _vm.pangea && !_vm.connect
                ? _c("span", [
                    _vm._v(
                      " You are about to create new Hosting and Pangea files for the current Ad. These files will be posted to the production FTP site for processing. "
                    ),
                  ])
                : _vm.hosting && !_vm.pangea && _vm.connect
                ? _c("span", [
                    _vm._v(
                      " You are about to create new Hosting and Connect files for the current Ad. These files will be posted to the production FTP site for processing. "
                    ),
                  ])
                : !_vm.hosting && _vm.pangea && _vm.connect
                ? _c("span", [
                    _vm._v(
                      " You are about to create new Pangea and Connect files for the current Ad. These files will be posted to the production FTP site for processing. "
                    ),
                  ])
                : _vm.hosting && !_vm.pangea && !_vm.connect
                ? _c("span", [
                    _vm._v(
                      " You are about to create a new Hosting file for the current Ad. These files will be posted to the production FTP site for processing. "
                    ),
                  ])
                : !_vm.hosting && _vm.pangea && !_vm.connect
                ? _c("span", [
                    _vm._v(
                      " You are about to create a new Pangea file for the current Ad. These files will be posted to the production FTP site for processing. "
                    ),
                  ])
                : !_vm.hosting && !_vm.pangea && _vm.connect
                ? _c("span", [
                    _vm._v(
                      " You are about to create a new Connect file for the current Ad. These files will be posted to the production FTP site for processing. "
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _vm.anyReruns
            ? _c(
                "div",
                [
                  _c("v-card-text", { staticClass: "mt-5" }, [
                    _vm._v(" " + _vm._s(_vm.warningText) + " "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close-dialog")
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-close")]),
                  _vm._v("No "),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-2",
                  attrs: { text: "", color: "green", loading: _vm.loading },
                  on: { click: _vm.generateFiles },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-checkbox-marked-circle-outline"),
                  ]),
                  _vm._v("Yes "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }